<template>
  <div class="formTitleComponent">
    <div class="geegz-background form_div_pre_title"
      style="background-color: black;"> </div>
  </div>
</template>

<script>
export default {
  name: 'formTitleComponent',
}
</script>
<style>
.geegz-background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.form_div_pre_title {
  height: 120px;
}
</style>
